/* eslint-disable */
export class Conf {

  static idempiereUrl = "https://idempiere.seritec.eu/"

  static windowTitle = 'CERTIFICO SERITEC'

  static buildNo = "20240923142021"

}
